<template>
  <div>
    <div class="container">
      <header-with-line header="Praktikum im Viavitum" class="mb-4"/>
    </div>
    <div class="container p-2">
      <div class="row m-auto d-flex justify-content-center align-items-center">
        <div class="col-lg-4 col-md-12 text-center">
            <img src="../../assets/img/karriere/praktikum.png" alt="Praktikantin" class="img-fluid img-border">
        </div>
        <div class="col-lg-8 col-md-12 fs-5 my-2 text-justify">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-9 col-md-12">
                    <div>
                        <img class="img-fluid mb-3" src="../../assets/img/decorations/recognition_internship_for_masseuses_and_medical.svg" alt="Fleckdekoration">
                    </div>
                </div>
            </div>
            <p>
              In unserem Therapiezentrum bieten wir offiziell anerkannte Praktikumsplätze
              für Masseurinnen und medizinische Bademeisterinnen an. Dank unserer
              <span class="text-bold">Genehmigung der Regierung Mittelfranken</span> kannst du
              sicher sein, dass dein Praktikum bei uns ordnungsgemäß abläuft und du dein Zeugnis
              rechtzeitig erhältst. Viele Praktikant*innen wissen nicht, dass sie ihr
              Anerkennungspraktikum nur in zugelassenen Einrichtungen absolvieren dürfen –
              bei uns brauchst du dir darüber keine Sorgen machen.
            </p>
            <p>
              <span class="text-bold">Ein weiteres Highlight:</span> Wenn du dich vor deinem
              Anerkennungspraktikum dafür entscheidest, im  Anschluss  bei uns als Therapeut*in
              zu arbeiten, profitierst du von einem Berufseinsteiger-Gehalt anstatt des Praktikumsgehalts.
              So startest du gleich zu fairen Konditionen und sicherst dir deinen Einstieg ins Berufsleben.
            </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'TraineePraxis',
    data() {
        return {};
    },
    components: {
        HeaderWithLine
    },
    methods: {},
    computed: {},
    mounted() {}
};
</script>
<style>
.img-border {
  border-radius: 25px;
  border: 5px solid #b0c34a;
}
</style>
