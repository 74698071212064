<template>
  <div>
    <div class="container">
      <header-with-line header="Gestalte dein Traumjob im Viavitum" class="mb-4"/>
      <div class="col-lg-9 mx-auto text-center">
        <div class="text-background h3">Logopädie, Physiotherapie und Egotherapie (m/w/d)</div>
      </div>
    </div>
    <div class="container p-2 fs-5 text-justify">
      <p>
        Du bist <span class="text-bold">Logopäd*in, Physiotherapeut*in oder Ergotherapeut*in (m/w/d)</span>
        und suchst eine erfüllende Tätigkeit in  <span class="textbold"> Vollzeit, Teilzeit</span> oder auf
        <span class="text-bold">geringfügiger</span> Basis? Im Therapiezentrum Viavitum in Windsbach erwarten
        dich vielseitige Chancen, um deine Fachkompetenz gezielt in der <span class="textbold">neurologischen
        Therapie</span> einzusetzen und gemeinsam mit einem engagierten Team die Lebensqualität unserer
        Patient*innen spürbar zu verbessern.
      </p>
      <p>
        Mit spezialisierten Behandlungsformen wie der <span class="text-bold">Therapie, Mukoviszidose-Therapie</span> und
        <span class="textbold">Neurofeedback</span> bieten wir dir die Möglichkeit, innovative Therapieansätze
        umzusetzen und dich kontinuierlich weiterzuentwickeln. Unsere moderne Praxis, flexible Arbeitszeitmodelle
        und regelmäßige Weiterbildungsmöglichkeiten schaffen ein Umfeld, in dem du sowohl beruflich als auch persönlich
        wachsen kannst.
      </p>
      <p>
        <span class="textbold">Schließe dich unserem Team an und gestalte aktiv die Zukunft der Therapie –
        Bewirb dich noch heute und werde Teil von Viavitum!</span>
      </p>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="text-center col-md-12 col-lg-4">
        <apply-now />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';
import ApplyNow from '../Ui/ApplyNow.vue';

export default {
    name: 'CreateDeramJob',
    components: {
        HeaderWithLine,
        ApplyNow
    },
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>

<style>
.text-bold {
  font-weight: bold;
}
</style>
