<template>
    <layout-default :sections="sections" :activeSection="activeSection">
        <section class="services bg-light py-1" id="dream-job" data-description="Karriere">
          <create-dream-job />
        </section>
        <section class="services bg-white py-1">
            <dream-profession />
        </section>
        <section class="services bg-light py-1">
            <work-life-balance />
        </section>
        <section class="services bg-white pt-0 pb-4">
            <div class="container">
              <personality-development />
            </div>
        </section>
        <section class="services bg-light py-1">
            <div class="container">
              <service-support />
            </div>
        </section>
        <section class="services bg-white py-1" id="job-offers" data-description="Stellenangebote">
            <div class="container">
              <job-offers />
            </div>
        </section>
        <section class="services bg-light py-1" id="trainee-praxis" data-description="Praktikum">
            <div class="container">
              <trainee-praxis />
            </div>
        </section>
        <section class="services bg-white py-1">
            <div class="container">
              <your-benefits />
            </div>
        </section>
        <section class="services bg-light py-1">
            <div class="container">
              <career-with-prospects />
            </div>
        </section>
        <section class="services bg-white py-1">
            <div class="container">
              <school-partner />
            </div>
        </section>
        <section class="services bg-light py-1" id="interest-aroused" data-description="Bewerben">
            <div class="container">
              <interest-aroused />
            </div>
        </section>
        <section class="services bg-light py-1">
            <div class="container">
              <apply />
            </div>
        </section>
    </layout-default>
</template>

<script>
import { collectSections, observeSections } from '../utils/sectionCollector';
import LayoutDefault from '../layouts/LayoutDefault.vue';
import Apply from '../components/Career/Apply.vue';
import DreamProfession from '../components/Career/DreamProfession.vue';
import WorkLifeBalance from '../components/Career/WorkLifeBalance.vue';
import PersonalityDevelopment from '../components/Career/PersonalityDevelopment.vue';
import ServiceSupport from '../components/Career/ServiceSupport.vue';
import JobOffers from '../components/Career/JobOffers.vue';
import CreateDreamJob from '../components/Career/CreateDreamJob.vue';
import TraineePraxis from '../components/Career/TraineePraxis.vue';
import YourBenefits from '../components/Career/YourBenefits.vue';
import CareerWithProspects from '../components/Career/CareerWithProspects.vue';
import SchoolPartner from '../components/Career/SchoolPartner.vue';
import InterestAroused from '../components/Career/InterestAroused.vue';

export default {
    name: 'Karriere',
    components: {
        LayoutDefault,
        Apply,
        DreamProfession,
        WorkLifeBalance,
        PersonalityDevelopment,
        ServiceSupport,
        JobOffers,
        CreateDreamJob,
        TraineePraxis,
        YourBenefits,
        CareerWithProspects,
        SchoolPartner,
        InterestAroused
    },
    data() {
        return {
            sections: [],
            activeSection: ''
        };
    },
    methods: {
        updateActiveSection(id) {
            this.activeSection = id;
        }
    },
    mounted() {
        this.sections = collectSections();
        observeSections(this.sections, this.updateActiveSection);
    }
};
</script>
