<template>
  <div>
    <div class="container">
      <div class="row m-auto d-flex justify-content-center align-items-center">
        <div class="col-lg-6 col-md-12 fs-5 text-justify p-0">
            <div class="row d-flex justify-content-center">
                <div class="col">
                    <div>
                        <img class="img-fluid mb-3" src="../../assets/img/decorations/your_benefits_during.svg" alt="Fleckdekoration">
                    </div>
                </div>
            </div>
              <ul class="custom-list text-primary h2 p-1">
                <li>
                    <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                    <div>Offiziell anerkanntes Praktikum:<br />
                    <span class="fs-5 text-muted">Genehmigung durch der Regierung Mittelfranken</span></div>
                </li>
                <li>
                    <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                    <div>Volle Team-Integration:<br />
                    <span class="fs-5 text-muted">Teilnahme an Team-Events und Fortbildungstagen</span></div>
                </li>
                <li>
                    <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                    <div>Strukturierter Lernplan:<br />
                    <span class="fs-5 text-muted">Begleitung durch erfahrene Therapeut*innen</span></div>
                </li>
                <li>
                    <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                    <div> Perspektive auf Übernahme:<br />
                    <span class="fs-5 text-muted">Direkte Einstiegsmöglichkeit nach dem Praktikum</span></div>
                </li>
                <li>
                    <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                    <div>Fortbildungszertifikate:<br />
                    <span class="fs-5 text-muted">Möglichkeit zur Teilnahme an Fortbildungen</span></div>
                </li>
                <li>
                    <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                    <div>Karriere mit Sicherheit:<br />
                    <span class="fs-5 text-muted">Berufseinsteiger-Gehalt bei anschließender Festanstellung</span></div>
                </li>
                <li>
                    <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                    <div>Persönliches Mentoring:<br />
                    <span class="fs-5 text-muted">Individuelle Unterstützung und Anleitung für deine berufliche Entwicklung</span></div>
                </li>
              </ul>
            </div>
          <div class="col-lg-6 col-md-12 text-center">
              <img src="../../assets/img/karriere/bescheid_small.png" alt="Praktikantin" class="img-fluid img-border-blue">
          </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'TraineePraxis',
    data() {
        return {};
    },
    components: {
    },
    methods: {},
    computed: {},
    mounted() {}
};
</script>
<style>
.img-border-blue {
  border-radius: 25px;
  border: 5px solid #1e3a8a;
}

.custom-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    hyphens: auto;
}

.custom-list img {
    height: 40px;
    margin-right: 10px;
}
</style>
