<template>
  <div>
    <div class="container">
      <header-with-line header="Interesse geweckt?" class="mb-4"/>
    </div>
    <div class="container p-2 fs-5">
      <p>
        Schreib uns einfach über das Kontaktformular oder melde dich unverbindlich per WhatsApp. Hier zählt dein Interesse und deine Persönlichkeit – egal, ob Berufseinsteigerin oder erfahrener Therapeut*in. Gemeinsam finden wir heraus, wie du bei uns den perfekten Platz findest. Wir freuen uns auf dich!“
      </p>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="text-center col-md-12 col-lg-4">
        <apply-now-whats-app />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';
import ApplyNowWhatsApp from '../Ui/ApplyNowWhatsApp.vue';

export default {
    name: 'CreateDeramJob',
    components: {
        HeaderWithLine,
        ApplyNowWhatsApp
    },
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>

<style>
.text-bold {
  font-weight: bold;
}
</style>
