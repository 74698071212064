<template>
    <div class="">
        <header-with-line header="Persönlichkeitsentwicklung" class="mb-4 text-break px-0"/>
        <div class="row">
            <div class="col-lg-7 mx-auto mb-3 img-container-phone">
                <div class="position-relative text-center mb-4">
                    <img class="img-fluid mb-4" src="../../assets/img/decorations/thinkin_about_the_future_together.svg" alt="Fleckdekoration">
                </div>
                <ul class="custom-list text-primary h2">
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <span>Fortbildung mit Kostenübernahme und 5 Fortbildungstage (bei Vollzeit)</span>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <span>Moderne Räume und digitales Arbeiten mit Tablets</span>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <span>300,- Euro Willkommens-Budget für Dein Lieblings-Therapiematerial</span>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <span>Unterstützung bei der beruflichen und persönlichen Entwicklung als Therapeut*in</span>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <span>Eigener Messenger</span>
                    </li>
                </ul>
                <div class="position-relative text-center mt-4">
                    <img class="img-fluid mt-4" src="../../assets/img/decorations/lets_grow_together.svg" alt="Fleckdekoration">
                </div>
            </div>
            <div class="col-lg-4 text-center  d-flex justify-content-center align-items-center">
                <div class="video-container-phone">
                  <iframe
                  src="https://www.youtube.com/embed/UXNyBMCwN00?autoplay=1&disablekb=1&loop=1&mute=1&modestbranding=1&color=white&iv_load_policy=3&playlist=UXNyBMCwN00"
                  >
                  </iframe>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-12">
            <FeedbackCard
              avatar="../../assets/img/karriere/mariaavatar.png"
              text="Die Ausbildung bei Das Viavitum ist für mich eine echte Entfaltungsmöglichkeit. Hier wird mir nicht nur viel beigebracht, sondern ich bekomme auch Verantwortung und kann mich in echten Projekten beweisen. Besonders schätze ich die offene Kommunikation und die flexiblen Arbeitsmöglichkeiten. Als Teil eines modernen Teams habe ich nicht nur Fachwissen, sondern auch digitale Skills und kreative Denkansätze gelernt, die mich fit für die Zukunft machen. Es fühlt sich an, als könnte ich hier wirklich etwas bewegen!"
            />
          </div>
          <!--
          <div class="col-lg-6 col-12">
            <FeedbackCard
              avatar="../../assets/img/karriere/mariaavatar.png"
              text="Die Ausbildung bei Das Viavitum ist für mich eine echte Entfaltungsmöglichkeit. Hier wird mir nicht nur viel beigebracht, sondern ich bekomme auch Verantwortung und kann mich in echten Projekten beweisen. Besonders schätze ich die offene Kommunikation und die flexiblen Arbeitsmöglichkeiten. Als Teil eines modernen Teams habe ich nicht nur Fachwissen, sondern auch digitale Skills und kreative Denkansätze gelernt, die mich fit für die Zukunft machen. Es fühlt sich an, als könnte ich hier wirklich etwas bewegen!"
            />
          </div>
          -->
        </div>
    </div>
</template>
<script>
import HeaderWithLine from '@/components/Ui/HeaderWithLine.vue';
import FeedbackCard from '@/components/Career/FeedbackCard.vue';

export default {
    name: 'PersonalityDevelopment',
    components: {
        HeaderWithLine,
        FeedbackCard
    },
    data() {
        return {};
    },
    methods: {},
    computed: {},
    mounted() {}
};
</script>
<style scoped>
.img-container-phone {
    position: relative;
}

.custom-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.custom-list img {
    height: 40px;
    margin-right: 10px;
}

.video-container-phone {
    background-color: #08425e;
    padding: 40px;
    border-radius: 60px;
    height: 765px;
    width: 402px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.video-container-phone iframe {
    height: 100%;
    width: 100%;
    padding-top: 55px;
    padding-bottom: 55px;
    border: none;
}

.video-container-phone::before {
    content: "";
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 30%;
    height: 10px;
    background-color: white;
    border-radius: 5px;
}

.video-container-phone::after {
    content: "";
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
}

@media screen and (max-width: 1400px) {
  .video-container-phone {
    background-color: #08425e;
    padding: 40px;
    border-radius: 60px;
    height: 660px;
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .video-container-phone iframe {
      height: 100%;
      width: 100%;
      padding-top: 55px;
      padding-bottom: 55px;
      border: none;
  }

  .video-container-phone::before {
      content: "";
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
      width: 30%;
      height: 10px;
      background-color: white;
      border-radius: 5px;
  }

  .video-container-phone::after {
      content: "";
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      height: 50px;
      background-color: white;
      border-radius: 50%;
  }
}

@media screen and (max-width: 1200px) {
    .video-container-phone {
        background-color: #08425e;
        padding: 30px;
        border-radius: 40px;
        height: 559px;
        width: 312px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .video-container-phone iframe {
        height: 100%;
        width: 100%;
        padding-top: 45px;
        padding-bottom: 55px;
        border: none;
    }

    .video-container-phone::before {
        content: "";
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        width: 30%;
        height: 10px;
        background-color: white;
        border-radius: 5px;
    }

    .video-container-phone::after {
        content: "";
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
    }
}

@media screen and (max-width: 992px) {
  .video-container-phone {
        background-color: #08425e;
        padding: 30px;
        border-radius: 40px;
        height: 679px;
        width: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .video-container-phone iframe {
        height: 100%;
        width: 100%;
        padding-top: 45px;
        padding-bottom: 55px;
        border: none;
    }

    .video-container-phone::before {
        content: "";
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        width: 30%;
        height: 10px;
        background-color: white;
        border-radius: 5px;
    }

    .video-container-phone::after {
        content: "";
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
    }
}

@media screen and (max-width: 768px) {
    .video-container-phone {
        background-color: #08425e;
        padding: 30px;
        border-radius: 40px;
        height: 610px;
        width: 312px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .video-container-phone iframe {
        height: 100%;
        width: 100%;
        padding-top: 45px;
        padding-bottom: 55px;
        border: none;
    }

    .video-container-phone::before {
        content: "";
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        width: 30%;
        height: 10px;
        background-color: white;
        border-radius: 5px;
    }

    .video-container-phone::after {
        content: "";
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
    }
}

@media screen and (max-width: 576px) {
    .video-container-phone {
      height: 600px;
      width: 312px;
      border-radius: 50px;
    }
}

</style>
