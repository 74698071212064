<template>
    <div class="col-lg-12 justify-content-md-center">
        <div class="row">
            <header-with-line header="… oder nutze unser Kontaktformular" class="mb-5"/>
        </div>
        <div class="row">
            <div class="px-5">
                <div class="row gy-4">
                    <div class="col-lg-12">
                        <input class="form-control" v-model="name" type="text" name="name" placeholder="Name"
                            :disabled="success">
                    </div>
                    <div class="col-lg-6">
                        <input class="form-control" v-model="email" type="text" name="email" placeholder="E-Mail"
                            :disabled="success">
                    </div>
                    <div class="col-lg-6">
                        <input class="form-control" v-model="phone" type="tel" name="phone" placeholder="Telefon"
                            :disabled="success">
                    </div>
                    <label for="department" class="col-lg-6 col-form-label">Deine Leidenschaft</label>
                    <div class="col-lg-6">
                        <select class="form-select" v-model="department" name="department" id="department"
                            :disabled="success">
                            <option value="physio">Physiotherapie</option>
                            <option value="ergo">Ergotherapie</option>
                            <option value="logo">Logopädie </option>
                            <option value="trainee">Anerkennungspraktikum</option>
                            <option value="support">Service & Support</option>
                        </select>
                    </div>
                    <div class="col-lg-12">
                        <textarea class="form-control" v-model="message" name="message" rows="5"
                            placeholder="Deine Nachricht an uns" :disabled="success"></textarea>
                        <small><span class="text-muted">(Schreibe uns kurz, nach welcher Art Job du suchst
                                und was du bisher
                                so getan hast.)</span></small>
                    </div>
                    <div class="col-lg-12">
                        <button v-if="!success" :disabled="!enableSubmit" class="btn btn-tertiary color-background w-100"
                            v-on:click="sendApplication"><i class="fas fa-envelope"></i> Senden</button>
                        <p class="text-success" v-if="success">Vielen Dank für Deine Nachricht! Wir melden
                            uns innerhalb der
                            nächsten zwei Tage bei dir.</p>
                        <p class="text-danger" v-if="error">Oops. Da ist etwas schief gelaufen. Probiers
                            gerne nochmal oder
                            ruf uns einfach an: <a v-bind:href="`tel:${$t('frame.phone')}`">{{
                                $t('frame.phone') }}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderWithLine from '@/components/Ui/HeaderWithLine.vue';
import axios from '../../services/axiosConfig';

export default {
    name: 'Apply',
    components: {
        HeaderWithLine
    },
    data() {
        return {
            success: false,
            error: false,
            name: '',
            email: '',
            phone: '',
            department: 'ergo',
            message: ''
        };
    },
    computed: {
        enableSubmit() {
            return this.email !== '' && this.phone !== '';
        }
    },
    methods: {
        sendApplication() {
            this.success = false;
            this.error = false;
            axios.post('bewerbung/', {
                name: this.name, email: this.email, phone: this.phone, department: this.department, message: this.message
            }).then((response) => {
                if (response.data.status === 1) {
                    this.success = true;
                } else {
                    this.error = true;
                }
            }).catch((error) => {
                console.error(error);
                this.error = true;
            });
        }
    }
};
</script>

<style scoped>
.text-stroked {
    font-family: 'HP Simplified';
    color: white;
    font-size: 45px;
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    -webkit-text-stroke: 2px #08425e;
}

@media screen and (max-width: 1399px) {
    .text-stroked {
        font-size: 2.8vw;
        -webkit-text-stroke: 0.15vw #08425e;
    }
}

@media screen and (max-width: 991px) {
    .text-stroked {
        font-size: 5vw;
        -webkit-text-stroke: 0.25vw #08425e;
    }
}

@media screen and (max-width: 767px) {
    .text-stroked {
        font-size: 4.5vw;
        -webkit-text-stroke: 0.25vw #08425e;
    }
}

@media screen and (max-width: 575px) {
    .text-stroked {
        font-size: 6.7vw;
        -webkit-text-stroke: 0.4vw #08425e;
    }
}
</style>
