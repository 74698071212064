<template>
    <div class="container">
        <div class="row">
            <header-with-line header="Service und Support" class="mb-5"/>
        </div>
        <div class="row">
            <div class="col-lg-7 mx-auto text-center mb-4">
                <div class="laptop-container">
                    <img class="img-fluid" src="../../assets/img/karriere/support.jpg" alt="Service und Support" />
                </div>
                <div class="lapbottom-container"></div>
            </div>
            <div class="col-lg-5 mx-automb-3 d-flex flex-column justify-content-around">
                <div class="position-relative">
                    <img class="img-fluid" src="../../assets/img/decorations/achieve_more_together.svg" alt="Fleckdekoration">
                </div>
                <ul class="custom-list text-primary h3">
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <span>Rezeption für Organisation & Patientenkoordination</span>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <span>Entlastung im Therapiealltag</span>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <span>Organisation des Praxis- und Therapiematerials</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row my-4">
            <div class="col-lg-12 mx-auto text-center">
                <div class="img-container">
                    <img class="img-fluid mb-3" src="../../assets/img/decorations/we_create_working_enviroment.svg" alt="Fleckdekoration">
                </div>
            </div>
        </div>
        <div class="container text-center my-2">
          <apply-now />
        </div>
    </div>
</template>
<script>
import HeaderWithLine from '@/components/Ui/HeaderWithLine.vue';
import ApplyNow from '../Ui/ApplyNow.vue';

export default {
    name: 'DreamProfession',
    components: {
        HeaderWithLine,
        ApplyNow
    },
    data() {
        return {};
    },
    methods: {},
    computed: {},
    mounted() {}
};
</script>
<style scoped>
.img-container {
    position: relative;
}

.text-stroked {
    font-family: 'HP Simplified';
    color: white;
    font-size: 45px;
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    -webkit-text-stroke: 2px #08425e;
}

.custom-text-002 {
    font-family: 'HP Simplified';
    color: white;
    font-size: 45px;
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    -webkit-text-stroke: 2px #08425e;
}

.custom-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.custom-list img {
    height: 40px;
    margin-right: 10px;
}

.laptop-container {
    background-color: #08425e; /* Синий цвет фона */
    border: #b0c34a 3px solid; /* Цвет верхней границы */
    margin-left: 50px; /* Отступ слева */
    margin-right: 50px; /* Отступ справа */
    padding: 20px; /* Добавляем немного внутреннего отступа */
    border-top-left-radius: 30px; /* Закругленный верхний левый угол */
    border-top-right-radius: 30px; /* Закругленный верхний правый угол */
    border-bottom-left-radius: 0; /* Прямой нижний левый угол */
    border-bottom-right-radius: 0; /* Прямой нижний правый угол */
}

.lapbottom-container {
    background-color: #b0c34a; /* Зелёный цвет фона */
    height: 20px; /* Высота прямоугольника */
    margin-top: -10px; /* Отрицательный отступ сверху для соединения с верхним контейнером */
    z-index: -1; /* Помещаем прямоугольник за верхний контейнер */
    border-bottom-left-radius: 30px; /* Закругленный верхний левый угол */
    border-bottom-right-radius: 30px; /* Закругленный верхний правый угол */
    border-top-left-radius: 0; /* Прямой нижний левый угол */
    border-top-right-radius: 0; /* Прямой нижний правый угол */
}

@media screen and (max-width: 1399px) {
    .text-stroked {
        font-size: 2.8vw;
        -webkit-text-stroke: 0.15vw #08425e;
    }
    .custom-text-002 {
        font-size: 3vw;
        -webkit-text-stroke: 0.15vw #08425e;
    }
}

@media screen and (max-width: 991px) {
    .text-stroked {
        font-size: 5vw;
        -webkit-text-stroke: 0.25vw #08425e;
    }
    .custom-text-002 {
        font-size: 3.6vw;
        -webkit-text-stroke: 0.18vw #08425e;
    }
}

@media screen and (max-width: 767px) {
    .text-stroked {
        font-size: 4.5vw;
        -webkit-text-stroke: 0.25vw #08425e;
    }
    .custom-text-002 {
        font-size: 3.6vw;
        -webkit-text-stroke: 0.2vw #08425e;
    }
}

@media screen and (max-width: 575px) {
    .text-stroked {
        font-size: 6.7vw;
        -webkit-text-stroke: 0.4vw #08425e;
    }
    .custom-text-002 {
        font-size: 4.5vw;
        -webkit-text-stroke: 0.28vw #08425e;
    }

    .laptop-container {
        background-color: #08425e; /* Синий цвет фона */
        border: #b0c34a 3px solid; /* Цвет верхней границы */
        margin-left: 20px; /* Отступ слева */
        margin-right: 20px; /* Отступ справа */
        padding: 10px; /* Добавляем немного внутреннего отступа */
        border-top-left-radius: 10px; /* Закругленный верхний левый угол */
        border-top-right-radius: 10px; /* Закругленный верхний правый угол */
        border-bottom-left-radius: 0; /* Прямой нижний левый угол */
        border-bottom-right-radius: 0; /* Прямой нижний правый угол */
    }
}
</style>
