<template>
  <div>
    <div class="container p-2">
      <div class="row m-auto d-flex justify-content-center align-items-center">
        <div class="col-lg-4 col-md-12 text-center">
            <img src="../../assets/img/karriere/happy_trainee.png" alt="Praktikantin" class="img-fluid img-border">
        </div>
        <div class="col-lg-8 col-md-12 fs-5 my-2 text-justify">
            <div class="row d-flex justify-content-center">
                <div>
                    <img class="img-fluid mb-3" src="../../assets/img/decorations/career_with_prospects.svg" alt="Fleckdekoration">
                </div>
            </div>
            <p>
              <span class="text-bold">Rund 80 % unserer Therapeut*innen</span>
              haben ihre Karriere bei uns als Praktikantinnen begonnen
              und sich schnell dazu entschieden,
              Teil unseres Teams zu werden. Uns ist es wichtig,
              dass Praktikantinnen bei uns nicht einfach Arbeit abnehmen, sondern Wissen aufnehmen.
              Bei vielen Praktikumsstellen werden Praktikant*innen als günstige Arbeitskraft genutzt
              und müssen Therapien ohne Supervision übernehmen – das ist weder richtig noch rechtens.
            </p>
            <p>
              <span class="text-bold">Bei uns läuft das anders:</span> Wir legen großen Wert auf ein
              strukturiertes Vorgehen. Praktikant*innen bekommen Einblicke in alle Facetten ihres
              Berufsfeldes und haben unter Supervision die Möglichkeit, ihre Fähigkeiten in
              der Praxis anzuwenden. Gleichzeitig bieten wir Raum für Reflexion und eigenständiges Arbeiten.
              Du siehst nicht nur die therapeutischen Abläufe bei uns im Zentrum,
              sondern auch im Außendienst, etwa in sozialen Einrichtungen.
            </p>
            <p>
              Als Teil unseres Teams nimmst du an <span class="text-bold">Teambesprechungen</span>
              und <span class="text-bold">Fortbildungstagen</span> teil und erhältst sogar
              ein Zertifikat für Fortbildungen, sofern diese während deines Praktikumszeitraums stattfinden.
            </p>
        </div>
      </div>
    </div>
    <div class="container py-1 text-center">
      <apply-now />
    </div>
  </div>
</template>

<script>
import ApplyNow from '../Ui/ApplyNow.vue';

export default {
    name: 'CareerWithProspects',
    data() {
        return {};
    },
    components: {
        ApplyNow
    },
    methods: {},
    computed: {},
    mounted() {}
};
</script>
<style>
.img-border {
  border-radius: 25px;
  border: 5px solid #b0c34a;
}
</style>
