<template>
    <div class="container">
        <header-with-line header="Dein Traumberuf" class="mb-5"/>
        <div class="row">
            <div class="col-lg-6 mx-auto mb-4 text-center d-flex flex-column justify-content-center">
              <div class="video-container">
                <iframe
                  src="https://www.youtube.com/embed/kGiy_64CBIM?autoplay=1&disablekb=1&loop=1&mute=1&modestbranding=1&color=white&iv_load_policy=3&playlist=kGiy_64CBIM"
                >
                </iframe>
              </div>
              </div>
            <div class="col-lg-6 mx-auto mb-3 img-container">
              <div class="row d-flex justify-content-center">
                    <div class="col-lg-8 col-md-12">
                        <div>
                            <img class="img-fluid mb-3" src="../../assets/img/decorations/convince_yourself.svg" alt="Fleckdekoration">
                        </div>
                    </div>
                </div>
                <ul class="custom-list text-primary h3">
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <span>Interdisziplinär und Blick über den Tellerrand</span>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <span>Intressante und auf dich angepasste Karrieremöglichkeiten</span>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <span>Fahr- & Reisekosten Erstattung </span>
                    </li>
                    <li>
                        <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                        <span>4 Teamtage im Jahr mit Weiterbildung & Teamevents</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'DreamProfession',
    data() {
        return {};
    },
    components: {
        HeaderWithLine
    },
    methods: {},
    computed: {},
    mounted() {}
};
</script>
<style scoped>
.custom-text-001 {
    font-family: 'HP Simplified';
    font-size: 90px;
    white-space: nowrap;
}

.custom-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.custom-list img {
    height: 40px;
    margin-right: 10px;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
