<template>
  <div class="review-container">
    <div class="stars">
      ★★★★★
    </div>
    <div class="review-card">
      <div>
        <div
          class="profile-picture"
        >
        </div>
        <div class="stars-mobile text-center">
            ★★★★★
        </div>
      </div>
      <div class="quote-text-box text-center text-background">
        <span class="quote-start">“</span>
        <span class="text-bold">Reise bei Das Viavitum</span><br />
        <span>{{ text }}</span>
        <span class="quote-end">”</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'FeedbackCard',
    props: {
        avatar: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped>
.review-container {
    margin-top: 75px;
}

.review-card {
    display: flex;
    justify-content: space-between;
    background-color: var(--bs-background);
    border-radius: 0px 40px 40px 40px;
}

.profile-picture {
    width: 150px;
    height: 150px;
    border: 15px solid #b2c385;
    border-radius: 50%;
    background-image: url('../../assets/img/karriere/mariaavatar.png');
    background-size: cover;
    background-position: center;
    position: relative;
    top: -75px;
}

.profile-picture::before {
    content: '';
    position: absolute;
    top: -15px;
    left: -15px;
    width: 150px;
    height: 150px;
    border: 10px solid #ffffff;
    border-radius: 50%;
}

.stars {
    color: #f6ce5d;
    font-size: 40px;
    position: relative;
    left: 160px;
}

.quote-text-box {
    background-color: white;
    padding: 20px 30px;
    border-radius: 30px;
    position: relative;
    right: 0px;
    margin: 10px 10px 10px 0px;
}

.quote-start, .quote-end {
    color: #b2c385;
    font-size: 60px;
}

.quote-start {
    position: absolute;
    top: 5px;
    left: 10px;
}

.quote-end {
    position: absolute;
    bottom: 10px;
    right: 5px;
}

.stars-mobile {
    display: none;
}
@media screen and (max-width: 767px) {
    .stars {
        display: none;
    }

    .stars-mobile {
        display: block;
        color: #f6ce5d;
        font-size: 30px;
        margin-top: 10px;
        position: relative;
        top: -65px;
    }

    .quote-text-box {
        margin: 10px 10px -55px 10px;
        position: relative;
        top: -65px;
    }

    .review-card {
        flex-direction: column;
        align-items: center;
        padding: 10px;
        border-radius: 40px 40px 40px 40px;
    }

    .profile-picture {
        width: 140px;
        height: 140px;
        border: 10px solid #b2c385;
        margin-bottom: 0px; /* Разделение от quote-text-box */
    }
    .quote-start, .quote-end {
        font-size: 40px; /* Уменьшаем кавычки */
    }
}
</style>
