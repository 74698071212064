<template>
  <div class="container fs-5 my-3">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-11 col-md-12">
        <img class="img-fluid mb-3" src="../../assets/img/decorations/strong_together_partnership.svg" alt="Fleckdekoration">
      </div>
    </div>
    <p>
      Darüber hinaus sind wir Kooperationspartner regionaler Berufsschulen für Heilmittelerbringer*innen
      und bieten regelmäßig Praktikumsplätze an. So unterstützen wir angehende Fachkräfte bereits während
      ihrer Ausbildung und begleiten sie auf ihrem Weg ins Berufsleben.
    </p>
    <div class="d-flex justify-content-around align-items-center">
      <div>
        <img class="img-fluid img-box-partner" src="https://kybalion.info/fileadmin/pix/logo_og.png" alt="Schulpartner">
      </div>
      <div>
        <img class="img-fluid img-box-partner" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzuY-GS02UQasj405-Z9d1mAGf-n29Pmwk7g&s" alt="Schulpartner">
      </div>
      <div>
        <img class="img-fluid img-box-partner" src="https://static.wixstatic.com/media/2fbc48_7c4250cab3124eb8b1ae60db372c5330~mv2.jpg/v1/crop/x_53,y_0,w_1469,h_1417/fill/w_310,h_300,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Logo-PGSMedau-farbe.jpg" alt="Schulpartner">
      </div>
    </div>
  </div>
</template>
<script>

export default {
    name: 'SchoolPartner',
    data() {
        return {};
    },
    components: {
    },
    methods: {},
    computed: {},
    mounted() {}
};

</script>
<style>
.img-box-partner {
    height: 200px;
    width: auto;
    object-fit: contain;
}
</style>
