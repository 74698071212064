<template>
  <div>
    <div class="container">
      <header-with-line header="Stellen­angebote" class="mb-4"/>
      <div class="col-lg-9 mx-auto text-center">
        <div class="text-primary h3">Starte deine Karriere bei uns!</div>
      </div>
    </div>
    <div class="container my-3 fs-5">
      <p>
        Wir suchen nicht nur qualifizierte Logopäd*innen, Physiotherapeut*innen und
        Ergotherapeut*innen, sondern vor allem Menschen mit Herz! Wenn du Teil eines
        Teams sein möchtest, das sich genauso um dein Wohl kümmert wie um das der Patientinnen,
        dann bewirb dich bei uns. Wir freuen uns darauf, dich kennenzulernen!
      </p>
    </div>
    <div class="container mb-3">
      <div class="col-lg-9 mx-auto text-center">
        <div class="text-primary h1">Wir suchen:</div>
      </div>
    </div>
    <div class="accordion px-1" role="tablist">
      <b-card no-body v-for="(offer, index) in offers" :key="index">
        <b-card-header header-tag="header" role="tab">
          <b-button block v-b-toggle="'accordion-' + index" class="d-flex align-items-center justify-content-center position-relative">
            <div class="mx-auto fs-3">{{ offer.title }} <span class="text-lime">(m/w/d)</span></div>
            <i :class="accordionOpen[index] ? 'far fa-minus-circle mr-2 text-size' : 'far fa-plus-circle mr-2 text-size'"></i>
          </b-button>
        </b-card-header>
        <b-collapse
          :id="'accordion-' + index"
          accordion="my-accordion"
          role="tabpanel"
          v-model="accordionOpen[index]"
        >
          <b-card-body>
            <b-card-text>
              <h5>{{ offer.subtitleFirst }}</h5>
            </b-card-text>
            <b-card-text>{{ offer.description }}</b-card-text>
            <b-card-text>
              <h5>Was dich bei uns erwartet:</h5>
            </b-card-text>
            <b-card-text>
              <ul class="w-lg-50">
                <li v-for="(profilItem, profilIndex) in splitProfil(offer.expectations)" :key="profilIndex">{{ profilItem.trim() }}</li>
              </ul>
            👉 Hier siehst du alle unsere Benefits im Überblick
            </b-card-text>
            <b-card-text>
              <h5>Deine Mission bei Viavitum:</h5>
            </b-card-text>
            <b-card-text>{{ offer.mission}}</b-card-text>
            <b-card-text>
              <h5>Dein Profil:</h5>
            </b-card-text>
            <b-card-text>
              <ul class="w-lg-50">
                <li v-for="(profilItem, profilIndex) in splitProfil(offer.profil)" :key="profilIndex">{{ profilItem.trim() }}</li>
              </ul>
            </b-card-text>
            <b-card-text>
              <h5>Bereit für deinen nächsten Karriereschritt?</h5>
            </b-card-text>
            <b-card-text>{{ offer.applyNow }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <div class="container my-3 text-center">
      <apply-now />
    </div>
  </div>
</template>

<script>
import {
    BCard,
    BCardBody,
    BCardText,
    BCollapse,
    BButton,
    BCardHeader
} from 'bootstrap-vue';
import HeaderWithLine from '../Ui/HeaderWithLine.vue';
import ApplyNow from '../Ui/ApplyNow.vue';

export default {
    name: 'JobOffers',
    components: {
        BCard,
        BCardBody,
        BCardText,
        BCollapse,
        BButton,
        BCardHeader,
        HeaderWithLine,
        ApplyNow
    },
    data() {
        return {
            offers: [
                {
                    title: 'Physiotherapeut*in',
                    subtitleFirst: 'Physiotherapeut*in (m/w/d) in Vollzeit, Teilzeit oder geringfügiger Basis – Gestalte mit uns die Zukunft der Therapie!',
                    description: 'Du bist Physiotherapeut*in (m/w/d) und suchst eine Stelle, die dir nicht nur berufliche Sicherheit, sondern auch spannende Perspektiven und echte Flexibilität bietet? Im Therapiezentrum Viavitum in Windsbach haben wir genau das, was du suchst! Mit uns hast du die Möglichkeit, moderne Therapieansätze zu leben und gemeinsam im Team eine „spürbar andere“ Therapie zu gestalten.',
                    expectations: `4-Tage-Woche und flexible Arbeitszeiten: Wir bieten dir die Möglichkeit, deinen Job optimal an deine Lebenssituation anzupassen.;
                                  Vielfältige Einsatzbereiche: Neben der Arbeit im Zentrum wirst du auch in sozialen Einrichtungen tätig sein – wie z. B. in Behinderteneinrichtungen, Seniorenzentren und der Frühförderung. So erweiterst du deine Fähigkeiten und erhältst wertvolle Einblicke in unterschiedliche Settings.;
                                  Individuelles Onboarding und kontinuierliche Förderung: Egal ob Berufseinsteiger*in oder erfahrene Therapeut*in, wir begleiten dich mit einem strukturierten Onboarding und gezielten Fortbildungen, um deine Fähigkeiten stetig auszubauen.;
                                  Karrierechance Fachliche Leitung: Wenn du bereits Erfahrung hast und Lust auf mehr Verantwortung, bieten wir dir die Option, als Fachliche Leitung unser Physiotherapie-Team weiterzuentwickeln.;
                                  Digitales Arbeiten leicht gemacht: Unsere modernen digitalen Tools erleichtern dir die Dokumentation und Organisation, damit du dich voll auf deine Patient*innen konzentrieren kannst.
                                  `,
                    mission: 'Als Teil unseres Teams unterstützt du Patient*innen mit neurologischen Erkrankungen, Skoliose und Mukoviszidosedabei, ihre Bewegungsfähigkeit und Lebensqualität nachhaltig zu verbessern. Mit einem interdisziplinären Ansatz und modernsten Therapieansätzen hilfst du ihnen, ein selbstbestimmtes Leben zu führen und neue Perspektiven zu gewinnen.',
                    profil: `Abgeschlossene Ausbildung als Physiotherapeutin (m/w/d) – Berufseinsteigerinnen sind willkommen!;
                            Interesse oder Erfahrung in Neurologie, Skoliose und/oder Mukoviszidose – wir unterstützen dich, diese Skills zu entwickeln.;
                            Engagement und Teamgeist: Du bringst Einfühlungsvermögen mit und arbeitest gern im Team.;
                            Eigenständiges Arbeiten: Du übernimmst Verantwortung und entwickelst dich stetig weiter.;
                            Bereitschaft zur Weiterentwicklung: Offenheit für Neues und die Motivation, dein Fachwissen laufend zu erweitern.
                            `,
                    applyNow: 'Dann bewirb dich jetzt und werde Teil des Viavitum-Teams! Gemeinsam gestalten wir die Therapie von morgen – innovativ, interdisziplinär und patientenzentriert.'
                },
                {
                    title: 'Logopäd*in',
                    subtitleFirst: 'Leitung Logopädie (m/w/d) – Starte mit uns durch und gestalte den Aufbau!',
                    description: 'Im Therapiezentrum Viavitum in Windsbach haben wir uns auf neurologische Rehabilitation spezialisiert und suchen eine engagierte Logopäd*in (m/w/d), die unseren Bereich Logopädie aufbaut und weiterentwickelt. Als Leitung hast du die Möglichkeit, die Logopädie als wichtigen Baustein unserer ganzheitlichen Patientenversorgung zu etablieren und dich in einem interdisziplinären Team aus Physiotherapie und Ergotherapie zu entfalten. Ob du die Verantwortung einer Leitungsfunktion übernehmen oder dich vor allem auf die Therapie konzentrieren möchtest – bei uns findest du den passenden Platz!',
                    expectations:
                        `
                          4-Tage-Woche und flexible Arbeitszeitmodelle: Erlebe eine bessere Work-Life-Balance und gestalte deine Arbeitszeit so, dass sie zu deinem Leben passt.;
                          Gestaltungsfreiheit: Baue den Bereich Logopädie von Grund auf mit auf und bring deine Ideen ein, um die logopädischen Angebote bei Viavitum aktiv zu formen.;
                          Vielseitige Einsatzmöglichkeiten: Neben der Arbeit im Zentrum kannst du auch in sozialen Einrichtungen, Kindertagesstätten und Frühförderungseinrichtungen tätig sein. So sammelst du wertvolle Erfahrungen und erreichst vielfältige Patientengruppen.;
                          Interdisziplinäre Zusammenarbeit: Arbeite eng mit Kolleginnen aus der Physiotherapie und Ergotherapiezusammen, um eine ganzheitliche Betreuung für unsere Patientinnen zu gewährleisten.;
                          Schwerpunkte in der Neurorehabilitation: Unterstütze Kinder und Erwachsene mit neurologischen Einschränkungen wie Sprach-, Sprech-, Stimm- und Schluckstörungen und hilf ihnen, ihre kommunikativen Fähigkeiten zu stärken.;
                          Individuelle Förderung und Weiterentwicklung: Profitiere von regelmäßigen Fortbildungen und Schulungen, um deine fachliche und persönliche Entwicklung voranzutreiben.
                        `,
                    mission:
                        `
                          Als Logopädin bei Viavitum unterstützt du Patientinnen mit sprachlichen und kommunikativen Einschränkungen, ihre Selbstständigkeit und Lebensqualität zu steigern. Deine Arbeit trägt dazu bei, Sprach-, Sprech-, Stimm- und Schluckstörungen gezielt zu behandeln und ihnen eine aktive Teilhabe am Alltag zu ermöglichen. In deiner Rolle als Leitung kümmerst du dich zusätzlich um den Aufbau des Logopädiebereichs und die Entwicklung eines Teams, das eng mit den anderen Disziplinen im Zentrum zusammenarbeitet.
                        `,
                    profil:
                        `
                          Abgeschlossene Ausbildung in Logopädie (m/w/d) – auch Leitungserfahrung ist willkommen, aber kein Muss.;
                          Leitungskompetenz und Eigeninitiative: Du bist bereit, den Bereich aufzubauen und die logopädischen Therapien bei Viavitum zu gestalten.;
                          Fachliche Schwerpunkte in der Neurorehabilitation: Interesse oder Erfahrung in der Behandlung von neurologischen Sprach-, Sprech-, Stimm- und Schluckstörungen.;
                          Teamgeist und interdisziplinärer Austausch: Du schätzt die enge Zusammenarbeit mit Kolleg*innen aus der Physiotherapie und Ergotherapie und trägst aktiv zum fachlichen Austausch bei.;
                          Flexibilität im Einsatzbereich: Du arbeitest gerne sowohl im Zentrum als auch in sozialen Einrichtungen, Kindertagesstätten und Frühförderung.;
                          Technische Affinität: Du nutzt digitale Tools für Dokumentation und Organisation und schätzt deren Vorteile für eine effiziente Arbeitsweise.
                        `,
                    applyNow: 'Dann bewirb dich jetzt und werde Teil eines Teams, das Therapie wirklich „spürbar anders“ macht. Wir freuen uns darauf, dich kennenzulernen und gemeinsam den Bereich Logopädie aufzubauen!'
                },
                {
                    title: 'Ergotherapeut*in',
                    subtitleFirst: 'Ergotherapeut*in (m/w/d) – Gestalte mit uns die Zukunft der Ergotherapie!',
                    description: 'Du bist Ergotherapeut*in (m/w/d) und hast Lust, gemeinsam mit einem motivierten Team innovative Ansätze in der Ergotherapie zu verwirklichen? Im Therapiezentrum Viavitum in Windsbach setzen wir auf die neurologische Rehabilitation und bauen unseren Ergotherapiebereich weiter aus. Wenn du Teil eines interdisziplinären Teams werden und echte Veränderung bewirken möchtest, dann freuen wir uns auf dich!',
                    expectations:
                        `
                          4-Tage-Woche und flexible Arbeitszeitmodelle: Erlebe eine neue Work-Life-Balance und gestalte deine Arbeitszeit so, dass sie zu deinem Leben passt.;
                          Vielseitige Einsatzmöglichkeiten: Neben der Arbeit im Zentrum kannst du auch in sozialen Einrichtungen, Kindertagesstätten, Frühförderung und Seniorenzentren tätig sein. So sammelst du wertvolle Erfahrungen in verschiedenen Settings und lernst, mit unterschiedlichen Patientengruppen zu arbeiten.;
                          Intensive Einarbeitung und kontinuierliche Entwicklung: Wir bieten ein strukturiertes Onboarding und regelmäßige Fortbildungen, die dir die Diagnosen, Therapieplanung und Berichterstellung in der neurologischen Rehabilitation näherbringen – ideal für Berufseinsteiger*innen und erfahrene Therapeut*innen.;
                          Neurofeedback und moderne Therapiemethoden: Lerne Neurofeedback als innovative Methode kennen und anwenden. Hast du bereits Erfahrung damit? Super! Ansonsten begleiten wir dich auf deinem Weg, diese spannende Methode zu erlernen.;
                          Teamarbeit und interdisziplinärer Austausch: Arbeite eng mit Kolleg*innen aus der Physiotherapie und Logopädie zusammen und profitiere von regelmäßiger Supervision und Team-Events.
                        `,
                    mission: 'Als Ergotherapeutin unterstützt du Patientinnen mit neurologischen und motorischen Einschränkungen dabei, ihre Selbstständigkeit und Lebensqualität zurückzugewinnen. In Einzel- und Gruppentherapien hilfst du ihnen, mehr Teilhabe und Freude im Alltag zu erleben – eine wertvolle Arbeit, die einen echten Unterschied macht.',
                    profil:
                        `
                          Abgeschlossene Ausbildung als Ergotherapeutin (m/w/d) – auch Berufseinsteigerinnen sind willkommen!;
                          Interesse oder Erfahrung in der neurologischen Rehabilitation – ob mit oder ohne Vorerfahrung, wir unterstützen dich dabei, deine Kenntnisse in diesem Bereich zu erweitern.;
                          Begeisterung für moderne Therapiemethoden wie Neurofeedback – Vorerfahrung ist ein Plus, aber kein Muss.;
                          Teamgeist und Flexibilität: Du schätzt den interdisziplinären Austausch und arbeitest gerne sowohl im Zentrum als auch in sozialen Einrichtungen, Kindertagesstätten und Frühförderung.;
                          Motivation und Gestaltungswille: Du möchtest aktiv den Bereich Ergotherapie mitgestalten und unsere Angebote im Bereich der neurologischen Rehabilitation weiterentwickeln.
                        `,
                    applyNow: 'Dann bewirb dich jetzt und werde Teil des Viavitum-Teams – dort, wo Therapie wirklich „spürbar anders“ ist!'
                }
            ],
            accordionOpen: []
        };
    },
    methods: {
        splitProfil(profil) {
            return profil.split(';');
        }
    },
    mounted() {
        this.accordionOpen = this.offers.map(() => false);
    }
};
</script>
<style scoped>
.text-lime {
    color: var(--bs-text);
}

.text-size {
    font-size: 2em;
    color: var(--bs-text);
}
</style>
